import React, { useEffect, useState } from "react";
import "./style.css";
import trans from "../images/trans.png";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/http/config/axios";
import { ToastContainer, toast } from "react-toastify";
import detailIcon from "../images/view-row.png";
import refundIcon from "../images/del-row.png";
import "react-toastify/dist/ReactToastify.css";
import { Accordion, Modal } from "react-bootstrap";

function Transaction() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [refreshTransactions, setRefreshTransactions] = useState(false);
  const [comment, setComment] = useState("");
  const [reason, setReason] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axiosInstance.get("/api/students/transactions");
        setTransactions(response.data);
        setLoading(false);
      } catch (err) {
        setError("Échec de la récupération des transactions");
        toast.error("Échec de la récupération des transactions");
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [refreshTransactions]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (transactionId) => {
    navigate(`/achats/${transactionId}`);
  };

  const handleRefund = () => {
    setOpenModal(true);
  };

  const handleChooseReason = (reason) => {
    setReason(reason);
  };

  const handleSubmitRefund = async (paymentId) => {
    if (!reason) {
      toast.error(
        "Veuillez sélectionner un motif pour la demande de remboursement."
      );
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axiosInstance.post(
        `/api/students/remboursement/${paymentId}`,
        {
          reason,
          comment: reason === "Autre" ? comment : "",
        }
      );

      if (response.data.success) {
        toast.success(
          "Votre demande de remboursement a été envoyée avec succès !"
        );
        setOpenModal(false);
        setRefreshTransactions(!refreshTransactions);
      } else {
        toast.error(
          response.data.message ||
            "Une erreur est survenue, veuillez réessayer."
        );
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          "Une erreur est survenue lors de la demande de remboursement. Veuillez réessayer plus tard."
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = transactions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="transaction">
      <ToastContainer />
      <div className="container-fluid">
        <div className="wrapping">
          <h3>Mes Transactions</h3>

          {transactions.length === 0 ? (
            <div className="text-center">
              <img src={trans} alt="No Transactions" />
              <p>Aucune transaction pour le moment !</p>
            </div>
          ) : (
            <div className="eva-time table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>Date</th>
                    <th>Méthode</th>
                    <th>Achat</th>
                    <th>Prix</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTransactions.map((transaction, index) => (
                    <tr key={transaction.id} style={{ cursor: "pointer" }}>
                      <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                      <td>{new Date(transaction.date).toLocaleDateString()}</td>
                      <td>{transaction.method}</td>
                      <td>{transaction.achat}</td>
                      <td>{transaction.prix} €</td>
                      <td>{transaction.status}</td>
                      <td className="d-flex align-items-center gap-2">
                        <img
                          src={refundIcon}
                          onClick={() => handleRefund()}
                          style={{ margin: "0px" }}
                        />
                        <img
                          src={detailIcon}
                          onClick={() => handleRowClick(transaction.id)}
                          style={{ margin: "0px" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <Pagination className="mb-0">
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {[...Array(Math.ceil(transactions.length / itemsPerPage))].map(
              (_, pageIndex) => (
                <Pagination.Item
                  key={pageIndex + 1}
                  active={currentPage === pageIndex + 1}
                  onClick={() => handlePageChange(pageIndex + 1)}
                >
                  {pageIndex + 1}
                </Pagination.Item>
              )
            )}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(transactions.length / itemsPerPage)
              }
            />
            <Pagination.Last
              onClick={() =>
                handlePageChange(Math.ceil(transactions.length / itemsPerPage))
              }
              disabled={
                currentPage === Math.ceil(transactions.length / itemsPerPage)
              }
            />
          </Pagination>
        </div>
      </div>

      {openModal && (
        <Modal
          onHide={() => setOpenModal(false)}
          show={openModal}
          className="refund-modal"
        >
          <Modal.Header>
            <h2 className="refund-modal-title">Demande de remboursement</h2>
          </Modal.Header>
          <Modal.Body>
            <Accordion className="refund-reasons" defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Quel est le motif de votre demande ?
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Facturation incorrecte" &&
                      "active-refund-reason"
                    }`}
                    onClick={() => handleChooseReason("Facturation incorrecte")}
                  >
                    Facturation incorrecte
                  </div>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Problème avec le service" &&
                      "active-refund-reason"
                    }`}
                    onClick={() =>
                      handleChooseReason("Problème avec le service")
                    }
                  >
                    Problème avec le service
                  </div>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Erreur dans la commande" &&
                      "active-refund-reason"
                    }`}
                    onClick={() =>
                      handleChooseReason("Erreur dans la commande")
                    }
                  >
                    Erreur dans la commande
                  </div>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Non-satisfaction" && "active-refund-reason"
                    }`}
                    onClick={() => handleChooseReason("Non-satisfaction")}
                  >
                    Non-satisfaction
                  </div>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Duplicata" && "active-refund-reason"
                    }`}
                    onClick={() => handleChooseReason("Duplicata")}
                  >
                    Duplicata
                  </div>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Autre" && "active-refund-reason"
                    }`}
                    onClick={() => handleChooseReason("Autre")}
                  >
                    Autre
                  </div>

                  {/* Conditionally render the input field if 'Autre' is selected */}
                  {reason === "Autre" && (
                    <div className="refund-comment">
                      <label
                        htmlFor="refund-comment-input"
                        className="form-label"
                      >
                        Votre commentaire:
                      </label>
                      <textarea
                        id="refund-comment-input"
                        className="form-control"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Entrez votre commentaire ici..."
                      />
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="refund-vld-choice"
              disabled={isSubmitting}
              onClick={() => handleSubmitRefund(currentTransactions[0].id)}
            >
              {isSubmitting ? "Chargement..." : "Valider"}
            </button>

            <button
              className="close-modal-btn"
              onClick={() => {
                setReason("");
                setOpenModal(false);
              }}
            >
              Fermer
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
}

export default Transaction;

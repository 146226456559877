import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/http/config/axios";
import { useNavigate } from "react-router-dom";
import "./style.css";
import back_arrows from "../images/back-arrows.svg";
import rseervation from "../images/rseervation.svg";
import Pagination from "react-bootstrap/Pagination";
import { Button, Modal } from "react-bootstrap";
import transsuccess from "../images/freepik__background__55199.png";
import { toast, ToastContainer } from "react-toastify";

function Myreservation() {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cancelLoading, setCancelLoading] = useState(null);
  const navigate = useNavigate();

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Function to go back to the previous page
  const goBack = () => {
    navigate(-1);
  };

  // Fetch the student dashboard (reservations) on component mount
  useEffect(() => {
    const fetchStudentDashboard = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Aucun jeton trouvé");
        }

        // Fetch reservations from the dashboard API
        const dashboardResponse = await axiosInstance.get(
          "/api/students/dashboard",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (dashboardResponse.data.success) {
          const { lessons } = dashboardResponse.data.data;
          setReservations(lessons);
        } else {
          // If no lessons are found, display the message from the API
          setError(
            dashboardResponse.data.message || "Aucune réservation trouvée"
          );
        }
      } catch (err) {
        setError(
          "Une erreur est survenue lors de la récupération des réservations"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDashboard();
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Annulation leçon !");
  const [modalText, setModalText] = useState(
    "Êtes-vous sûr de vouloir annuler cette leçon ?"
  );
  const [cancelledReservation, setCancelledReservation] = useState({
    id: undefined,
    status: undefined,
  });

  const handleCancelLesson = async () => {
    if (
      cancelledReservation.status === "réalisé" ||
      cancelledReservation.status === "en cours" ||
      cancelledReservation.status === "annulé"
    ) {
      toast.error(
        "La leçon ne peut pas être annulée car elle est déjà réalisée, en cours ou annulée."
      );
      setOpenModal(false);
      return;
    } else {
      try {
        setCancelLoading(cancelledReservation.id);
        const response = await axiosInstance.delete(
          `/api/students/lessons/${cancelledReservation.id}`
        );

        if (response.data.success) {
          setReservations((prevReservations) =>
            prevReservations.filter(
              (reservation) => reservation.id !== cancelledReservation.id
            )
          );
          toast.success("Leçon annulée avec succès.");
          setOpenModal(false);
        } else {
          toast.error(
            response.data.message || "Impossible d'annuler la leçon."
          );
          setOpenModal(false);
        }
      } catch (err) {
        toast.error(
          "Une erreur est survenue lors de l'annulation de la leçon."
        );
      } finally {
        setCancelLoading(null);
      }
    }
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return (
      <section className="myreservation">
        <div className="container-fluid">
          <div className="wrapping">
            <button
              type="button"
              onClick={goBack}
              className="btn btn-secondary btn-main"
            >
              <img src={back_arrows} alt="Retour" /> Retour
            </button>

            <div className="dont-reservation">
              <img src={rseervation} alt="rservation" />
              <div className="rser-content">
                <h3>{error}</h3>
                <button
                  type="button"
                  className="btn btn-secondary btn-main"
                  onClick={() => navigate("/dashboard")}
                >
                  Réservez maintenant
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentReservations = reservations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(reservations.length / itemsPerPage);
  const getPaginationItems = (currentPage, totalPages, range = 3) => {
    const pages = [];
    const start = Math.max(1, currentPage - Math.floor(range / 2));
    const end = Math.min(totalPages, currentPage + Math.floor(range / 2));

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <section className="myreservation">
      <div className="container-fluid">
        <div className="wrapping">
          <button
            type="button"
            onClick={goBack}
            className="btn btn-secondary btn-main"
          >
            <img src={back_arrows} alt="Retour" /> Retour
          </button>

          <>
            <h4>Mes réservations</h4>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Date et heure</th>
                    <th scope="col">Heure</th>
                    <th scope="col">Lieu</th>
                    <th scope="col">Instructeur</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentReservations.length > 0 ? (
                    currentReservations.map((reservation) => (
                      <tr key={reservation.id}>
                        <td>
                          {new Date(
                            reservation.timeslot?.date
                          ).toLocaleDateString()}
                        </td>
                        <td>
                          {reservation.timeslot?.startTime?.split(":")[0]}h-
                          {reservation.timeslot?.endTime?.split(":")[0]}h
                        </td>

                        <td>{reservation.location || "Non spécifiée"}</td>
                        <td>
                          {reservation.timeslot?.instructor?.firstName}{" "}
                          {reservation.timeslot?.instructor?.lastName}
                        </td>
                        <td>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              setModalTitle("Annulation leçon !");
                              setModalText(
                                "Êtes-vous sûr de vouloir annuler cette leçon ?"
                              );
                              setOpenModal(true);
                              setCancelledReservation({
                                id: reservation.id,
                                status: reservation.status,
                              });
                            }}
                            disabled={
                              cancelLoading === reservation.id ||
                              reservation.status === "réalisé" ||
                              reservation.status === "annulé" ||
                              reservation.status === "en cours"
                            }
                          >
                            {cancelLoading === reservation.id
                              ? "Annulation..."
                              : "Annuler"}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">Aucune réservation trouvée</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <Pagination className="mb-0">
              <Pagination.First
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              />

              {/* Generate page numbers dynamically */}
              {getPaginationItems(currentPage, totalPages).map((page) => (
                <Pagination.Item
                  key={page}
                  active={currentPage === page}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </Pagination.Item>
              ))}

              <Pagination.Next
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => setCurrentPage(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>

            {openModal && (
              <Modal
                show={openModal}
                onHide={() => setOpenModal(false)}
                className="trans-popup"
              >
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body>
                  <div className="text-center">
                    <h2 className=" m-0">{modalTitle}</h2>
                    <div className="">
                      <img
                        src={transsuccess}
                        alt="transaction"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <p className="m-0">
                      Êtes-vous sûr de vouloir annuler cette leçon ?
                    </p>
                  </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                  <div>
                    {modalText ===
                      "Êtes-vous sûr de vouloir annuler cette leçon ?" && (
                      <Button
                        variant="secondary"
                        className="btn-main text-white"
                        onClick={handleCancelLesson}
                      >
                        {cancelLoading === cancelledReservation.id
                          ? "Annulation..."
                          : "Confirmer"}
                      </Button>
                    )}
                    <Button
                      variant="secondary"
                      className={`${
                        modalText !==
                          "Êtes-vous sûr de vouloir annuler cette leçon ?" &&
                        "btn-main text-white"
                      } ms-2`}
                      onClick={() => setOpenModal(false)}
                    >
                      Retour
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal>
            )}
          </>
        </div>
      </div>
    </section>
  );
}

export default Myreservation;

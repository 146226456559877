import React, { useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/http/config/axios";

function Changepassword() {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const { currentPassword, newPassword, confirmPassword } = formData;

    if (newPassword !== confirmPassword) {
      setAlertType("error");
      setAlert("Les nouveaux mots de passe ne correspondent pas.");
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.put(
        "/api/students/profile/change-password",
        {
          oldPassword: currentPassword,
          newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setAlertType("success");
      setAlert(response.data.message || "Mot de passe mis à jour avec succès.");
      setTimeout(() => {
        setAlert("");
        setLoading(false);
        navigate(-1);
      }, 2000);
    } catch (error) {
      setLoading(false);

      if (error.response?.data.error === "Ancien mot de passe invalide") {
        setAlertType("error");
        setAlert("L'ancien mot de passe est incorrect. Veuillez réessayer.");
      } else {
        setAlertType("error");
        setAlert("Erreur lors de la mise à jour du mot de passe.");
      }
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <section className="changepassword">
      <div className="container">
        <div className="wrapping">
          <div className="back-btn">
            <button
              type="button"
              onClick={goBack}
              className="btn btn-secondary btn-main"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z"
                  fill="white"
                />
              </svg>
              Retour
            </button>
          </div>
          <div className="top-heading-profile">
            <h3 className=" ms-0 mb-3">Changer le mot de passe</h3>
          </div>
          {alert && (
            <div
              className={`alert alert-${
                alertType === "error" ? "danger" : "success"
              }`}
            >
              {alert}
            </div>
          )}
          <div className="form">
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="currentPassword" className="form-label">
                    Mot de passe actuel
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="currentPassword"
                    name="currentPassword"
                    value={formData.currentPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="newPassword" className="form-label">
                    Nouveau mot de passe
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPassword"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirmer votre mot de passe
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="submit-btn">
            <button
              type="button"
              className="btn btn-secondary btn-main"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Mise à jour..." : "Mettre à jour"}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Changepassword;

import React, { useState } from "react";
import verifyemail from "../images/verify-email.svg";
import "./style.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/http/config/axios";

function Verify_link() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError("Veuillez saisir une adresse e-mail valide.");
      return;
    }

    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "/api/students/reset-password",
        {
          email,
        }
      );

      if (response.status === 200) {
        setMessage(
          "Un lien de réinitialisation de mot de passe a été envoyé à votre adresse e-mail."
        );
        setError("");
      } else {
        setError(
          response.data.message ||
            "Une erreur est survenue. Veuillez réessayer."
        );
      }
    } catch (err) {
      setError(
        "Une erreur est survenue lors de l'envoi du lien de réinitialisation."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = () => {
    navigate("/");
  };

  return (
    <section className="verify-link verify_email">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mx-auto">
            <div className="wrapping">
              <div className="text-center">
                <img src={verifyemail} alt="Verify Email" />
              </div>
              <h3>Réinitialiser votre mot de passe</h3>
              <p className="text-center">
                Mot de passe oublié ? Veuillez saisir votre email et nous vous
                enverrons un lien
              </p>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Entrez votre email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {error && <p className="text-danger text-center">{error}</p>}
                {message && (
                  <p className="text-success text-center">{message}</p>
                )}
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-secondary btn-main mb-4 email-recove mt-4"
                    disabled={loading}
                  >
                    {loading ? "Chargement..." : "Soumettre"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-main mb-4 email-recove mt-4"
                    onClick={handleLogin}
                    style={{ marginLeft: "10px" }}
                  >
                    Connexion
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Verify_link;

import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/http/config/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NEPH() {
  const [nephCode, setNephCode] = useState("");
  const navigate = useNavigate();

  // Function to navigate back
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const storedNephCode = localStorage.getItem("nephCode");
    if (storedNephCode && storedNephCode !== "null") {
      setNephCode(storedNephCode);
    } else {
      setNephCode("");
    }
  }, []);

  const handleNephChange = (e) => {
    setNephCode(e.target.value);
  };

  const validateNephCode = () => {
    if (nephCode.length !== 12) {
      toast.error("Le code NEPH doit comporter exactement 12 chiffres.", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });
      return false;
    }
    return true;
  };

  const handleReserveCourse = () => {
    navigate("/dashboard");
  };

  const handleUpdateNeph = async () => {
    if (!validateNephCode()) return;

    try {
      const response = await axiosInstance.put("/api/students/neph-code", {
        nephCode,
      });

      toast.success("NEPH mis à jour avec succès !", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });

      localStorage.setItem("nephCode", nephCode);
    } catch (error) {
      toast.error(
        error.response?.data?.error || "Erreur lors de la mise à jour du NEPH.",
        {
          position: toast.TOP_RIGHT,
          autoClose: 3000,
        }
      );
    }
  };

  return (
    <section className="neph">
      <ToastContainer />
      <div className="container">
        <div className="wrapping">
          <div className="back-btn">
            <button
              type="button"
              onClick={goBack}
              className="btn btn-secondary btn-main"
            >
              Retour
            </button>
          </div>
          <div className="top-heading-profile">
            <h3 className="ms-0 mb-3">Votre NEPH</h3>
          </div>
          <div className="form">
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-3 copyico">
                  <label htmlFor="nephInput" className="form-label">
                    NEPH
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nephInput"
                    value={nephCode}
                    onChange={handleNephChange} // Update NEPH code as user types
                    placeholder="Entrez votre NEPH (12 chiffres)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="btn-bottom text-end neph-btns">
            <button
              type="button"
              onClick={handleUpdateNeph}
              className="btn btn-secondary btn-main"
            >
              Mettre à jour
            </button>
            <button
              type="button"
              onClick={handleReserveCourse}
              className="btn btn-outline-secondary btn-outline-main"
            >
              Réservez un cours
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NEPH;

import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import visa from "../images/visa-ico.png";
import mastercard from "../images/master_card.png";
import card_american_express from "../images/card_american_express.png";
import datecalc from "../images/date-calc.svg";
import axiosInstance from "../utils/http/config/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Achats() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/students/transaction/${id}`
        );

        // Check if response contains JSON
        if (response.headers["content-type"]?.includes("application/json")) {
          setTransaction(response.data);
        } else {
          throw new Error("La réponse n'est pas au format JSON attendu.");
        }

        setLoading(false);
      } catch (err) {
        toast.error(
          "Erreur lors de la récupération des détails de la transaction:",
          err
        );
        if (err.response?.status === 401) {
          setError("Authentification échouée. Veuillez vous reconnecter.");
          localStorage.removeItem("token");
          navigate("/");
        } else {
          setError(
            err.response?.data?.message ||
              "Erreur lors de la récupération des détails de la transaction"
          );
        }
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [id, navigate]);

  const goBack = () => {
    navigate(-1);
  };

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="acharts">
      <ToastContainer />
      <div className="container">
        <div className="wrapping">
          {/* Back button */}
          <div className="back-btn">
            <button
              type="button"
              onClick={goBack}
              className="btn btn-secondary btn-main"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z"
                  fill="white"
                />
              </svg>
              Retour
            </button>
          </div>

          {/* Title */}
          <div className="vote-neph">
            <h2>Retrouvez tous vos achats ici</h2>
          </div>

          {/* Transaction details */}
          {transaction && (
            <div className="facture-cp-notes">
              <div className="calc-date">
                <div className="dte">
                  <img src={datecalc} alt="datecalc" />
                  <span>{new Date(transaction.date).toLocaleDateString()}</span>
                </div>
                <strong>{transaction.prix} €</strong>
              </div>
              <div className="d-flex justify-content-between align-items-center cdf-no">
                <div className="CPF">
                  <strong>{transaction.id}</strong>
                  <p className="mb-0">
                    {transaction.achat
                      ? transaction.achat
                      : "Formation Licence CPF B"}
                  </p>
                </div>
                {/* Pass transaction ID via URL parameter to the Invoice page */}
                <Link to={`/invoice/${transaction.id}`}>
                  <button type="button" className="btn btn-secondary btn-main">
                    Détails
                  </button>
                </Link>
              </div>
            </div>
          )}

          {/* Payment methods */}
          <div className="means-paymnt">
            <div>
              <h4>Moyens de paiement</h4>
              <p>Vos modes de paiement sont stockés en toute sécurité.</p>
            </div>
            <div className="cards-paymnt">
              <img src={visa} alt="Visa" />
              <img src={mastercard} alt="Mastercard" />
              <img src={card_american_express} alt="American Express" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Achats;

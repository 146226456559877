import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import iconoir_edit from "../images/iconoir_edit.svg";
import ep_delete from "../images/ep_delete.svg";
import visa from "../images/visa-ico.png";
import card_american_express from "../images/card_american_express.png";
import master_card from "../images/master_card.png";
import checktick from "../images/check-tick.svg";

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  "pk_live_51OkET2EExNjl2VujeJWaAKncrqPTsybY6InGcYK4ksaQzVRxZ0BP6MOmMOpwAEy9kKyiRuH3Q8ks1GqFlRRXWWzQ00GeahxA51"
);

function Addtocart() {
  const location = useLocation();
  const { state } = location;
  const planDetails = state?.planDetails || {};

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleAlert = (message, type) => {
    setAlert({ show: true, message, type });
    setTimeout(() => setAlert({ show: false, message: "", type: "" }), 5000);
  };

  return (
    <section className="addcart">
      <Breadcrumb>
        <Breadcrumb.Item className="breadcrumb">
          <Link to="/boutique">Boutique</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Ajouter au panier</Breadcrumb.Item>
      </Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="cart-item">
              <div className="actions">
                <Link to="">
                  <img src={iconoir_edit} alt="modifier" />
                </Link>
                <Link to="">
                  <img src={ep_delete} alt="supprimer" />
                </Link>
              </div>
              <div className="d-flex justify-content-between align-items-center my-4 produt-dest">
                <div>
                  <h3>{planDetails.name || "Nom du produit"}</h3>
                  <span>
                    {planDetails.description ||
                      "Description du plan sélectionné"}
                  </span>
                </div>
                <strong>
                  {planDetails.price ? `${planDetails.price} €` : "Prix"}
                </strong>
              </div>
              {/* Display Descriptions */}
              <div className="descriptions">
                <h4>Descriptions :</h4>
                <ul>
                  {planDetails.descriptions?.map((desc, index) => (
                    <li key={index}>
                      {" "}
                      <span>
                        <img src={checktick} alt="tick" />
                      </span>
                      {desc}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="cart-total">
              <h4>Récapitulatif de la commande</h4>
              <div className="sub-total">
                <div className="d-flex justify-content-between">
                  <span>Sous-total</span>
                  <strong>
                    {planDetails.price ? `${planDetails.price} €` : "Prix"}
                  </strong>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Impôt</span>
                  <strong>0.00 €</strong>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <span>Total</span>
                  <strong>
                    {planDetails.price ? `${planDetails.price} €` : "Prix"}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row cards">
          <div className="card-title">
            <h4>Paiement 100% sécurisé</h4>
            <div className="payment-secure">
              <img src={visa} alt="Visa" />
              <img src={card_american_express} alt="American Express" />
              <img src={master_card} alt="MasterCard" />
            </div>
          </div>
          <div className="form">
            {alert.show && (
              <div className={`alert alert-${alert.type}`} role="alert">
                {alert.message}
              </div>
            )}
            <Elements stripe={stripePromise}>
              <CheckoutForm
                planDetails={planDetails}
                onSuccess={() => handleAlert("Paiement réussi!", "success")}
                onError={() =>
                  handleAlert(
                    "Le paiement a échoué. Veuillez réessayer.",
                    "danger"
                  )
                }
              />
            </Elements>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Addtocart;

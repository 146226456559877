import React, { useEffect, useState } from "react";
import paid from "../images/paid.svg";
import logo from "../images/logo.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../utils/http/config/axios";

function Invoice() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [transaction, setTransaction] = useState(
    location.state?.transaction || null
  );
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");

  const [loading, setLoading] = useState(!transaction);
  const [error, setError] = useState(null);

  const [othersInvoiceInfos, setOthersInvoiceInfos] = useState(false);

  useEffect(() => {
    if (!transaction) {
      const fetchTransactionDetails = async () => {
        try {
          const response = await axiosInstance.get(
            `/api/students/transaction/${id}`
          );
          setTransaction(response.data);
          setLoading(false);
        } catch (err) {
          toast.error(
            "Erreur lors de la récupération des détails de la transaction:",
            err
          );
          setError(
            "Erreur lors de la récupération des détails de la transaction."
          );
          setLoading(false);
        }
      };

      fetchTransactionDetails();
    }
  }, [id, transaction]);

  const goBack = () => {
    navigate(-1);
  };

  // fonction qui capitalise les chaines
  const capitalize = (str) => {
    if (!str) return ""; // Gérer les cas de chaîne vide ou null
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  // PDF download button function
  const downloadPDF = () => {
    setOthersInvoiceInfos(true);
    const invoice = document.getElementById("invoice");
    setOthersInvoiceInfos(true);
    html2canvas(invoice, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margin = 10; // Define margin as 10mm
      // Calculate the image width and height with margins
      const imgWidth = pageWidth - margin * 2; // Adjust to leave margin on both sides
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      // Position with the margin at the top
      const xPos = margin;
      const yPos = margin;
      pdf.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight);
      pdf.save("facture.pdf");
    });
    // setOthersInvoiceInfos(false)
  };

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="invoice container">
      <ToastContainer />
      <div className="wrapping mb-4">
        <div className="back-btn ms-5 mb-4 ">
          <button
            type="button"
            onClick={goBack}
            className="btn btn-secondary btn-main"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z"
                fill="white"
              />
            </svg>
            Retour
          </button>
        </div>
        <div className="invoice">
          <div className="invoice" id="invoice">
            <div className="text-center mb-4">
              <h1>FACTURE</h1>
              <img src={logo} />
            </div>

            <div className="d-flex justify-content-between">
              <div className="emet-infos">
                <h5>FACTURE No {id}</h5>
                <p className="mb-0">
                  <div>16 Av. du Colonel Rol-Tanguy</div>
                  <div>93240 Stains, France</div>
                  <div>+33 9 70 70 76 56</div>
                  <div>contact-lcr@gmp-auto-ecole.com</div>
                </p>

                <p className="">https://gmp-auto-ecole.com/</p>
              </div>

              <div className="recet-infos">
                <h6>
                  DATE D'EMISSION :{" "}
                  {new Date(transaction.date).toLocaleDateString()}
                </h6>
                <p className="">FACTURE A</p>
                <p className="">
                  <strong>
                    {firstName} {lastName}
                  </strong>
                </p>
              </div>
            </div>

            <div className="tables table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Description</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Qté</th>
                    <th scope="col">Montant total</th>
                  </tr>
                </thead>
                <tbody>
                  {transaction.description.split(",").map((desc, index) => (
                    <tr key={index}>
                      <th scope="row">{desc.trim()}</th>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))}
                  {/* <tr>
                    <th scope="row">{transaction.achat}</th>
                    <td>{transaction.prix} €</td>
                    <td>1</td>
                    <td>{transaction.prix} €</td>
                  </tr>
                  <tr>
                    <th scope="row">Total</th>
                    <td></td>
                    <td></td>
                    <td>{transaction.prix} €</td>
                  </tr> */}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-between">
              <div className="inv-infos">
                <h6>PAIEMENT</h6>
                <div>
                  <span>Par </span>
                  <strong>{capitalize(transaction.method)}</strong>
                </div>
              </div>
              <div className="inv-infos">
                <h6>STATUS</h6>
                <div>
                  <strong>
                    {transaction.status === "réussi" ? "Payé" : "En attente"}
                  </strong>
                </div>
              </div>
            </div>

            <div className="inv-total">
              <p className="inv-title">
                Abonnement du forfait <strong>{transaction.achat}</strong>
              </p>

              <div>
                <span>TOTAL A PAYER</span>
                <p>
                  <strong>{transaction.prix} €</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="downl-btn text-end m-4 mb-4">
            <button
              onClick={downloadPDF}
              type="button"
              className="btn btn-secondary btn-main"
            >
              Télécharger le reçu
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Invoice;

// function firstInvoice (){
//   return(
//     <section className="invoice">
//       <div className="back-btn mb-3 d-flex justify-content-end">
//         <button
//           type="button"
//           onClick={goBack}
//           className="btn btn-secondary btn-main"
//         >
//           <svg
//             width="19"
//             height="19"
//             viewBox="0 0 19 19"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               fillRule="evenodd"
//               clipRule="evenodd"
//               d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z"
//               fill="white"
//             />
//           </svg>
//           Retour
//         </button>
//       </div>
//       <div className="wrapping mb-4">
//         <div className="invoice">
//           <div className="invoice" id="invoice">
//             <div className="d-flex justify-content-between align-items-center mb-5">
//               <img src={logo} />
//               <div className="invoice-paid">
//                 <div className="d-flex gap-4 justify-content-between align-items-center mb-2">
//                   <p className="mb-0">Facture no : </p>
//                   <span>
//                     <strong>{id}</strong>
//                   </span>
//                 </div>
//                 <div className="d-flex gap-4 justify-content-between align-items-center mb-2">
//                   <p className="mb-0">Date : </p>
//                   <span>
//                     <strong>
//                       {new Date(transaction.date).toLocaleDateString()}
//                     </strong>
//                   </span>
//                 </div>
//                 <div className="d-flex gap-4 justify-content-between align-items-center">
//                   <p className="mb-0">Status : </p>
//                   <span
//                     style={{
//                       color: transaction.status === "réussi" ? "green" : "#000",
//                     }}
//                   >
//                     <strong>
//                       {transaction.status === "réussi" ? "Payé" : "En attente"}
//                     </strong>
//                   </span>
//                 </div>
//                 {/* <div className="d-flex gap-4 align-items-center">
//                     <p className="mb-0">Status</p>
//                   <div className="paid">
//                       <span>
//                         <img src={paid} alt="paid" />{" "}
//                         {transaction.status === "réussi" ? "Payé" : "En attente"}
//                       </span>
//                     </div>
//                   </div> */}
//               </div>
//             </div>

//             <div className="facture-infos">
//               <p className="">
//                 <span>
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 384 512"
//                     height={"18px"}
//                   >
//                     <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
//                   </svg>
//                 </span>
//                 16 Av. du Colonel Rol-Tanguy
//               </p>
//               <p className="">
//                 <span>
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 576 512"
//                     height={"18px"}
//                   >
//                     <path d="M460.3 241.7c25.8-41.3 15.2-48.8-11.7-48.8h-27c-.1 0-1.5-1.4-10.9 8-11.2 5.6-37.9 6.3-37.9 8.7 0 4.5 70.3-3.1 88.1 0 9.5 1.5-1.5 20.4-4.4 32-.5 4.5 2.4 2.3 3.8 .1zm-112.1 22.6c64-21.3 97.3-23.9 102-26.2 4.4-2.9-4.4-6.6-26.2-5.8-51.7 2.2-137.6 37.1-172.6 53.9l-30.7-93.3h196.6c-2.7-28.2-152.9-22.6-337.9-22.6L27 415.8c196.4-97.3 258.9-130.3 321.2-151.5zM94.7 96c253.3 53.7 330 65.7 332.1 85.2 36.4 0 45.9 0 52.4 6.6 21.1 19.7-14.6 67.7-14.6 67.7-4.4 2.9-406.4 160.2-406.4 160.2h423.1L549 96z" />
//                   </svg>
//                 </span>
//                 93240 Stains, France
//               </p>
//               <p className="">
//                 <span>
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 512 512"
//                     height={"18px"}
//                   >
//                     <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
//                   </svg>
//                 </span>
//                 +33 9 70 70 76 56
//               </p>
//               <div className="d-flex justify-content-between align-items-start mb-4">
//                 <p className="">
//                   <span>
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       viewBox="0 0 512 512"
//                       height={"18px"}
//                     >
//                       <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
//                     </svg>
//                   </span>
//                   contact@gmp-auto-ecole.com
//                 </p>
//                 <div>
//                   <p className="mb-3">
//                     <strong>
//                       {firstName} {lastName}
//                     </strong>
//                   </p>
//                 </div>
//               </div>
//             </div>

//             <div className="d-flex gap-2 mb-4">
//               <span>
//                 <strong>Intitulé</strong>:{" "}
//               </span>
//               <p className="m-0">Facture d'abonnement du forfait </p>
//             </div>

//             <div className=" invoice-details d-flex mb-4">
//               <div className="col-lg-6">
//                 {/* <div>
//                     <span>Émis le</span>
//                     <p>
//                       <strong>
//                         {new Date(transaction.date).toLocaleDateString()}
//                       </strong>
//                     </p>
//                   </div> */}
//                 <div className="mb-4">
//                   <span>Méthode de paiement</span>
//                   <p>
//                     <strong>{capitalize(transaction.method)}</strong>
//                   </p>
//                 </div>
//                 <div>
//                   <span>Plan</span>
//                   <p>
//                     <strong>{transaction.achat}</strong>
//                   </p>
//                 </div>
//                 <div>
//                   <span>Prix</span>
//                   <p>
//                     <strong>{transaction.prix} €</strong>
//                   </p>
//                 </div>
//               </div>

//               <div className="col-lg-6">
//                 <div className="mb-4">
//                   <span>Status</span>
//                   <p className="mb-0">
//                     <strong>{capitalize(transaction.status)}</strong>
//                   </p>
//                 </div>
//                 <div className="mb-4">
//                   <h5>Description</h5>
//                   {typeof transaction.description === "string" ? (
//                     <ul>
//                       {transaction.description.split(",").map((desc, index) => (
//                         <li
//                           key={index}
//                           className="mb-2 d-flex align-items-center gap-2"
//                         >
//                           <span>
//                             <img src={checktick} alt="tick" />
//                           </span>
//                           <span>{desc.trim()}</span>
//                         </li>
//                       ))}
//                     </ul>
//                   ) : (
//                     <p>
//                       {transaction.description ||
//                         "Aucune description disponible"}
//                     </p>
//                   )}
//                 </div>
//               </div>
//             </div>

//             <hr />
//             <h5>Éléments</h5>
//             <div className="tables table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th scope="col">Description</th>
//                     <th scope="col">Montant</th>
//                     <th scope="col">Qté</th>
//                     <th scope="col">Montant total</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <th scope="row">{transaction.achat}</th>
//                     <td>{transaction.prix} €</td>
//                     <td>1</td>
//                     <td>{transaction.prix} €</td>
//                   </tr>
//                   <tr>
//                     <th scope="row">Total</th>
//                     <td></td>
//                     <td></td>
//                     <td>{transaction.prix} €</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           </div>
//           <div className="text-end mt-4">
//             <button
//               onClick={downloadPDF}
//               type="button"
//               className="btn btn-secondary btn-main"
//             >
//               Télécharger le reçu
//             </button>
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import checktick from "../images/check-tick.svg";
import axiosInstance from "../utils/http/config/axios";
import "./style.css";

function Boutique() {
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get("/api/students/subscriptions");
        if (Array.isArray(response.data)) {
          setSubscriptions(
            response.data.map((sub) => ({
              ...sub,
              descriptions: (sub.descriptions || []).sort(
                (a, b) => a.id - b.id
              ),
            }))
          );
        } else if (response.data && Array.isArray(response.data.data)) {
          setSubscriptions(
            response.data.data.map((sub) => ({
              ...sub,
              descriptions: (sub.descriptions || []).sort(
                (a, b) => a.id - b.id
              ),
            }))
          );
        } else {
          setError(
            "Oups, on dirait qu'il y a un souci avec les informations. Veuillez réessayer plus tard."
          );
        }
      } catch (error) {
        setError(
          "Oups, une erreur est survenue. Nous n'avons pas pu récupérer vos abonnements. Essayez à nouveau dans un moment."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleBuy = (planDetails) => {
    navigate("/cart", {
      state: { planDetails, descriptions: planDetails.descriptions },
    });
  };

  const sortSubscriptionsByPrice = (subscriptions) =>
    subscriptions.sort((a, b) => a.price - b.price);

  const nonTestSubscriptions = subscriptions.filter(
    (sub) => sub.type !== "test"
  );
  const testSubscriptions = subscriptions.filter((sub) => sub.type === "test");
  const sortedSubscriptions = sortSubscriptionsByPrice(nonTestSubscriptions);
  const finalSubscriptions = [...sortedSubscriptions, ...testSubscriptions];

  const classiqueSubscriptions = finalSubscriptions
    .filter((sub) => sub.type === "classique")
    .map((sub) => ({
      ...sub,
      popular: parseInt(sub.price) === 1999,
    }));

  const accelereesSubscriptions = finalSubscriptions
    .filter((sub) => sub.type === "accelerees" || sub.type === "test")
    .map((sub) => ({
      ...sub,
      popular: parseInt(sub.price) === 1699,
    }));

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const classiqueChunks = chunkArray(classiqueSubscriptions, 4);
  const accelereesChunks = chunkArray(accelereesSubscriptions, 4);

  if (loading) {
    return <div>chargement...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <section className="shop">
      <div className="top-navs">
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title="Formules Classiques">
            <div className="wrap-cover">
              <div className="tab-cover">
                <h2>Formules Classiques</h2>
                <p>
                  Nos formules intensives sont idéales pour ceux qui souhaitent
                  obtenir leur permis de conduire rapidement tout en bénéficiant
                  d'une formation complète et approfondie.
                </p>
              </div>
              {classiqueChunks.map((chunk, rowIndex) => (
                <div className="row bg-white my-5 gy-4" key={rowIndex}>
                  {chunk.map((subscription) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6"
                      key={subscription.id}
                    >
                      <SubscriptionCard
                        subscription={subscription}
                        handleBuy={handleBuy}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Tab>

          {/* 
          <Tab eventKey="profile" title="Formules Accélérées">
            <div className="wrap-cover">
              <div className="tab-cover">
                <h2>Formules Accélérées</h2>
                <p>
                  {" "}
                  Nos formules intensives sont idéales pour ceux qui souhaitent
                  obtenir leur permis de conduire rapidement tout en bénéficiant
                  d'une formation complète et approfondie.
                </p>
              </div>
              {accelereesChunks.map((chunk, rowIndex) => (
                <div className="row bg-white my-5 gy-4" key={rowIndex}>
                  {chunk.map((subscription) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6"
                      key={subscription.id}
                    >
                      <SubscriptionCard
                        subscription={subscription}
                        handleBuy={handleBuy}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Tab>
          */}
        </Tabs>
      </div>
    </section>
  );
}

const SubscriptionCard = ({ subscription, handleBuy }) => (
  <div className={`plan-first ${subscription.popular ? "populr" : ""}`}>
    {subscription.popular && (
      <div className="popular">
        <span>Le plus populaire</span>
      </div>
    )}
    <h2>{parseInt(subscription.price)} €</h2>
    <p>{subscription.name}</p>
    <ul>
      {subscription.descriptions?.map((desc, index) => (
        <li key={index}>
          <span>
            <img src={checktick} alt="tick" />
          </span>
          {desc}
        </li>
      ))}
    </ul>
    <div className="text-center">
      <button
        type="button"
        className="btn btn-secondary btn-main acheter"
        onClick={() =>
          handleBuy({
            id: subscription.id,
            name: subscription.name,
            price: subscription.price,
            descriptions: subscription.descriptions,
          })
        }
      >
        Acheter
      </button>
    </div>
  </div>
);

export default Boutique;

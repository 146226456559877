import React from "react";
import PropTypes from "prop-types";
import Ellipse from "../images/user-placeholder.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar as fullStar,
  faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as emptyStar } from "@fortawesome/free-regular-svg-icons";

const ReviewCard = ({ review }) => {
  if (!review) {
    return <div>Chargement en cours...</div>;
  }

  const commentLabels = [
    "Qualité de l'enseignement",
    "Attitude et comportement du moniteur",
    "Réactions et réflexes",
  ];

  const CommentAnnotation = ({ label, index, ratingComment, ratingStars }) => {
    const renderStars = (rating) => {
      const fullStars = Math.floor(rating);
      const halfStars = rating % 1 >= 0.5 ? 1 : 0;
      const emptyStars = 5 - fullStars - halfStars;

      return (
        <>
          {[...Array(fullStars)].map((_, index) => (
            <FontAwesomeIcon
              width={"12px"}
              key={index}
              icon={fullStar}
              style={{ color: "#ffa500" }}
            />
          ))}
          {halfStars === 1 && (
            <FontAwesomeIcon
              width={"12px"}
              icon={faStarHalfAlt}
              style={{ color: "#ffa500" }}
            />
          )}
          {[...Array(emptyStars)].map((_, index) => (
            <FontAwesomeIcon
              width={"12px"}
              key={index}
              icon={emptyStar}
              style={{ color: "#ffa500" }}
            />
          ))}
        </>
      );
    };

    return (
      <>
        <div
          key={index}
          className="review-list mb-3 d-flex justify-content-between align-items-center"
        >
          <p className="comment-review mb-0">{`${index}. ${label}`}</p>
          {/* Star Rating */}
          <div className="rating-count">
            {/* <FaStar className="star-icon" /> */}
            <span className="rating-number">{renderStars(ratingStars)}</span>
          </div>
        </div>
        <div className="review-comments">
          {ratingComment || "Pas de commentaire."}
        </div>
      </>
    );
  };

  return (
    <div className="cardReview">
      {/* Student Information */}
      <div className="d-flex justify-content-between align-items-center mb-2 gap-1">
        <img
          src={
            review?.studentProfilePicture
              ? `/${review?.studentProfilePicture}`
              : Ellipse
          }
          alt={`${review.studentFirstName} ${review.studentLastName}`}
          className="profile-image img-fluid me-3"
        />

        {/* Review Date and Time */}
        <div>
          <div className="title-date">Date &amp; Heure</div>
          <div className="text-date">
            {new Date(review.createdAt).toLocaleString("fr-FR")}
          </div>
        </div>
      </div>

      <div>
        <h5 className="titleReview">{`${review.studentFirstName} ${review.studentLastName}`}</h5>
        <h6 className="card-subtitle text-muted">Étudiant</h6>
      </div>
      <hr />

      {/* Review Comments and Ratings */}
      <div>
        <CommentAnnotation
          index={"1"}
          label={commentLabels[0]}
          ratingStars={review.ratingTeaching}
          ratingComment={review.commentTeaching}
        />
        <CommentAnnotation
          index={"2"}
          label={commentLabels[1]}
          ratingStars={review.ratingCommunication}
          ratingComment={review.commentCommunication}
        />
        <CommentAnnotation
          index={"3"}
          label={commentLabels[2]}
          ratingStars={review.ratingReflexes}
          ratingComment={review.commentReflexes}
        />
      </div>
    </div>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.shape({
    studentFirstName: PropTypes.string.isRequired,
    studentLastName: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    ratings: PropTypes.arrayOf(PropTypes.number).isRequired,
    profileImage: PropTypes.string.isRequired,
  }).isRequired,
  commentLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ReviewCard;

// export function ReviewCardSecond({review}) {
//   return (
//     <div key={index} className="col-md-6 mb-4">
//     <div className="rev">
//         <div className="revby">
//         <div className="d-flex justify-content-between align-items-center">
//             <div className="d-flex justify-content-between align-items-center">
//             <img
//                 src={
//                 review?.studentProfilePicture
//                     ? `/${review?.studentProfilePicture}`
//                     : Ellipse
//                 }
//                 alt="Student"
//                 className="assigned-instructor-img"
//             />
//             <div className="ps-2">
//                 <h5 className="mb-0">
//                 {review.studentFirstName}{" "}
//                 {review.studentLastName}
//                 </h5>
//                 <p className="mb-0">Étudiant</p>
//             </div>
//             </div>
//             <div>
//             <h6 className="text-end">Date et heure</h6>
//             <h5 className="text-end mb-0">
//                 <strong>
//                 {new Date(
//                     review.createdAt
//                 ).toLocaleString("fr-FR")}
//                 </strong>
//             </h5>
//             </div>
//         </div>
//         <hr />
//         <div className="rev-pts">
//             <div className="pts">
//             <p className="mb-0">
//                 1. Qualité de l'enseignement
//             </p>
//             <div className="rating">
//                 {renderStars(review.ratingTeaching)}
//             </div>
//             </div>
//             <p>
//             {review.commentTeaching ||
//                 "Pas de commentaire."}
//             </p>
//         </div>
//         <hr />
//         <div className="rev-pts">
//             <div className="pts">
//             <p className="mb-0">
//                 2. Attitude et comportement du moniteur
//             </p>
//             <div className="rating">
//                 {renderStars(review.ratingCommunication)}
//             </div>
//             </div>
//             <p>
//             {review.commentCommunication ||
//                 "Pas de commentaire."}
//             </p>
//         </div>
//         <hr />
//         <div className="rev-pts">
//             <div className="pts">
//             <p className="mb-0">
//                 3. Réactions et réflexes
//             </p>
//             <div className="rating">
//                 {renderStars(review.ratingReflexes)}
//             </div>
//             </div>
//             <p>
//             {review.commentReflexes ||
//                 "Pas de commentaire."}
//             </p>
//         </div>
//         </div>
//     </div>
//     </div>
//   )
// }

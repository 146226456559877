import axios from "axios";
import { toast } from "react-toastify";

// Créez une instance Axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, // Remplacez par l'URL de base de votre API
  // timeout: 10000, // Délai d'expiration après 10 secondes
});

// Intercepteur de requêtes pour ajouter le token JWT aux en-têtes
axiosInstance.interceptors.request.use(
  (config) => {
    // Récupérez le token JWT depuis le localStorage ou une autre méthode de stockage
    const token = localStorage.getItem("token");

    // Si le token existe, ajoutez-le à l'en-tête Authorization
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Gérer les erreurs de requête ici
    return Promise.reject(error);
  }
);

// Intercepteur de réponses pour gérer les erreurs
axiosInstance.interceptors.response.use(
  (response) => {
    // Tout code de statut dans la plage 2xx déclenchera cette fonction
    return response;
  },
  (error) => {
    // Tout code de statut en dehors de la plage 2xx déclenchera cette fonction
    if (error.response) {
      // Gérer les réponses d'erreur spécifiques (par ex., 401, 403, etc.)
      switch (error.response.status) {
        case 400:
          console.error("Requête incorrecte - problème côté client.");
          break;
        case 401:
          console.error("Non autorisé - le token est peut-être expiré.");
          // Solution temporaire
          window.location = "/";
          // Rediriger vers la connexion ou logique de rafraîchissement du token ici
          break;
        case 403:
          console.error("Interdit - vous n’avez pas la permission d’accéder.");
          break;
        case 404:
          console.error("Non trouvé - la ressource demandée est indisponible.");
          break;
        case 500:
          console.error("Erreur interne du serveur - problème côté serveur.");
          toast(
            error.response.message ||
              "Une erreur s'est produite ! Veuillez réessayer plus tard."
          );
          break;
        // Gérer d'autres codes de statut si nécessaire
        default:
          console.error("Une erreur inattendue s’est produite.");
      }
    } else if (error.request) {
      // La requête a été faite mais aucune réponse n’a été reçue
      console.error("Aucune réponse reçue du serveur.");
    } else {
      // Une autre erreur est survenue lors de la configuration de la requête
      console.error(
        "Erreur lors de la configuration de la requête :",
        error.message
      );
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

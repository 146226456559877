import React, { useEffect, useState } from "react";
import staer from "../images/staer.png";
import newnoti from "../images/new-noti.svg";
import calcnoti from "../images/calcnoti.svg";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import Star from "./Star";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "react-bootstrap/Pagination";
import "react-toastify/dist/ReactToastify.css";
import { formatDistanceToNow, format } from "date-fns";
import { fr } from "date-fns/locale";
import axiosInstance from "../utils/http/config/axios";

const notificationIcons = {
  reservation: calcnoti,
  notification: staer,
  rappel: calcnoti,
  alerte: newnoti,
  paiement: calcnoti,
  "leçon terminée": calcnoti,
};

const formatNotificationTime = (createdAt) => {
  const now = new Date();
  const notificationTime = new Date(createdAt);

  if (notificationTime.getDate() === now.getDate() - 1) {
    return "hier";
  }

  if (now.getDate() - notificationTime.getDate() > 1) {
    return format(notificationTime, "dd/MM/yyyy à HH:mm", { locale: fr });
  }

  return formatDistanceToNow(notificationTime, { addSuffix: true, locale: fr });
};

const getPaginationItems = (currentPage, totalPages) => {
  const range = 2;
  let startPage = Math.max(currentPage - range, 1);
  let endPage = Math.min(currentPage + range, totalPages);

  const paginationItems = [];

  for (let i = startPage; i <= endPage; i++) {
    paginationItems.push(i);
  }

  return paginationItems;
};

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(15);
  const navigate = useNavigate();

  const paginationItems = getPaginationItems(currentPage, totalPages);

  const openModal = (noti) => {
    setSelectedNotification(noti);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleRatingClick = (reviewTypeId, ratingValue) => {
    setRatings((prev) => ({
      ...prev,
      [reviewTypeId]: ratingValue,
    }));
  };

  const handleCommentChange = (reviewTypeId, value) => {
    setComments((prev) => ({
      ...prev,
      [reviewTypeId]: value,
    }));
  };

  const handleReviewSubmit = async () => {
    if (!selectedNotification?.evaluationId) {
      toast.error("Aucune leçon associée à cet instructeur.");
      return;
    }

    const reviewData = {
      ratings: [
        {
          reviewTypeId: 1,
          ratingValue: ratings[1] || 0,
          comment: comments[1] || "",
        },
        {
          reviewTypeId: 2,
          ratingValue: ratings[2] || 0,
          comment: comments[2] || "",
        },
        {
          reviewTypeId: 3,
          ratingValue: ratings[3] || 0,
          comment: comments[3] || "",
        },
      ],
    };

    try {
      const response = await axiosInstance.post(
        `/api/students/instructors/${selectedNotification.evaluationId}/reviews`,
        reviewData
      );
      if (response.data.success) {
        toast.success("Commentaires soumis avec succès !");
        closeModal();
      } else {
        toast.error("Échec lors de la soumission des commentaires.");
      }
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la soumission des commentaires."
      );
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/students/notifications?page=${currentPage}`
        );
        setNotifications(response.data.notifications);
        setTotalPages(response.data.pagination.totalPages);
        setLoading(false);
      } catch (err) {
        setError("Échec lors de la récupération des notifications");
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [currentPage]);

  const getUrlByType = (type) => {
    switch (type) {
      case "reservation":
        return "/myreservation";
      case "notification":
        return "/notifications";
      case "rappel":
        return "/rappel";
      case "alerte":
        return "/alertes";
      case "paiement":
        return "/transaction";
      case "leçon terminée":
        return null;
      default:
        return "";
    }
  };

  const handleNotificationClick = (noti) => {
    if (noti.type === "leçon terminée") {
      try {
        const evaluationId = noti.evaluationId;

        if (!evaluationId) {
          toast.error("Aucune leçon associée à cet instructeur.");
        } else {
          setSelectedNotification({ ...noti, evaluationId });
          openModal(noti);
        }
      } catch (error) {
        toast.error("Erreur lors de l'ouverture de la notification.");
      }
    } else {
      const url = getUrlByType(noti.type);
      if (url) {
        navigate(url);
      }
    }
  };

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <section className="notifications">
      <ToastContainer />
      <Modal show={isModalOpen} onHide={closeModal} className="inst-feedback">
        <Modal.Header closeButton>
          <Modal.Title>Commentaires de l'instructeur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inst_rating">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  1. Qualité de l'enseignement
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    {[1, 2, 3, 4, 5].map((star, index) => (
                      <Star
                        key={index}
                        selected={index < (ratings[1] || 0)}
                        onClick={() => handleRatingClick(1, index + 1)}
                      />
                    ))}
                  </div>
                  <Form>
                    <Form.Group className="mb-3 mt-2">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Commentez ici..."
                        value={comments[1] || ""}
                        onChange={(e) => handleCommentChange(1, e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2. Attitude et comportement du moniteur
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    {[1, 2, 3, 4, 5].map((star, index) => (
                      <Star
                        key={index}
                        selected={index < (ratings[2] || 0)}
                        onClick={() => handleRatingClick(2, index + 1)}
                      />
                    ))}
                  </div>
                  <Form>
                    <Form.Group className="mb-3 mt-2">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Commentez ici..."
                        value={comments[2] || ""}
                        onChange={(e) => handleCommentChange(2, e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  3. Satisfaction globale de la leçon
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    {[1, 2, 3, 4, 5].map((star, index) => (
                      <Star
                        key={index}
                        selected={index < (ratings[3] || 0)}
                        onClick={() => handleRatingClick(3, index + 1)}
                      />
                    ))}
                  </div>
                  <Form>
                    <Form.Group className="mb-3 mt-2">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Commentez ici..."
                        value={comments[3] || ""}
                        onChange={(e) => handleCommentChange(3, e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleReviewSubmit}>
            Sauvegarder
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="container">
        <div className="wrapping">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={goBack}
              className="btn btn-secondary btn-main mb-3"
            >
              Retour
            </button>
          </div>
          <h5>Nouvelles notifications</h5>
          <hr />
          {notifications.length === 0 ? (
            <p>Aucune notification disponible</p>
          ) : (
            notifications.map((noti, index) => (
              <div
                key={index}
                className="noti-recent"
                onClick={() => handleNotificationClick(noti)}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex">
                  <img
                    src={notificationIcons[noti.type] || staer}
                    alt={noti.type}
                  />
                  <div className="ps-3">
                    <p className="mb-0">
                      <strong>{noti.title}</strong> {noti.message}
                    </p>
                    <div className="date-time">
                      <span>{formatNotificationTime(noti.createdAt)}</span>
                    </div>
                  </div>
                </div>
                {noti.isNew && (
                  <div className="new-noti">
                    <img src={newnoti} alt="Nouvelle notification" />
                  </div>
                )}
                <hr />
              </div>
            ))
          )}
          <Pagination className="mt-4">
            <Pagination.First
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            />

            {paginationItems.map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </Pagination.Item>
            ))}

            {currentPage + 2 < totalPages && <Pagination.Ellipsis disabled />}

            <Pagination.Next
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      </div>
    </section>
  );
}

export default Notifications;
